import { useEffect, useState, useMemo } from 'react';

import { FileRevisionID } from '@playq/octopus2-files';

import { useFileGetDownloadUrl } from '/api/hooks/filesService';
import { SourceType } from '/shared/FilePreview/types';
import { urlStorage } from '/shared/FilePreview/urlStorage';
import { isUrlExpired } from '/shared/FilePreview/helpers';
import { ProcessPreview } from '/shared/FilePreview/ProcessPreview';

import { IHandlerProps } from '../types';

export function FileRevisionHandler({ source, ...rest }: IHandlerProps<FileRevisionID>) {
  const persistedURL = useMemo(() => {
    const expectedUrl = urlStorage.get(source.serialize());
    return expectedUrl && !isUrlExpired(expectedUrl) ? expectedUrl : undefined;
  }, [source]);

  const [url, setUrl] = useState<string | undefined>(persistedURL);
  const [requestID, setRequestID] = useState<FileRevisionID | undefined>();
  const [loading, setLoading] = useState(!url);

  const { data, error } = useFileGetDownloadUrl(requestID, true);

  useEffect(() => {
    if (!persistedURL || persistedURL === data?.url) {
      return;
    }
    setUrl(persistedURL);
  }, [persistedURL, data?.url]);

  useEffect(() => {
    setRequestID(source);
  }, [source]);

  useEffect(() => {
    if (data?.url === undefined) {
      return;
    }

    urlStorage.set(source.serialize(), data.url);
    setUrl(data.url);
    setLoading(false);
  }, [data?.url, source]);

  useEffect(() => {
    if (error) {
      urlStorage.remove(source.serialize());
      setUrl(undefined);
      setLoading(false);
    }
  }, [error, source]);

  return <ProcessPreview url={url} loading={loading} {...rest} />;
}

FileRevisionHandler.canProcess = (source: SourceType): boolean => source instanceof FileRevisionID;
