/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, SyntheticEvent, useCallback, useContext, useState } from 'react';
import { Palette } from '@mui/material';

import { nativeFilePicker$ } from '/helpers';
import { FCWithChildren, IThemeModeContext } from '/common/models';
import { ThemeModeContext } from '/common';

import { Background, Container, Content, Foreground, ForegroundText, Root } from './styles';
import filesParser from './filesParser';

export interface IDropzoneProps {
  allowMultiple?: boolean;
  disable?: boolean;
  allowClick?: boolean;
  background?: string | number | ReactNode;
  border?: boolean;

  onDragEnter?: (event: DragEvent) => void;
  onDragLeave?: (event: DragEvent) => void;
  onHover?: (val: boolean) => void;
  onFilesRead: (files: File[]) => void;
}

export const Dropzone: FCWithChildren<IDropzoneProps> = (props) => {
  const {
    disable = false,
    allowClick = false,
    allowMultiple = false,
    border = false,
    background,
    children,

    onDragEnter,
    onDragLeave,
    onHover,
    onFilesRead,
  } = props;

  const [hovering, setHovering] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);

  const handleClick = () => {
    if (!allowClick || disable) {
      return;
    }

    return (e: SyntheticEvent) => {
      e.preventDefault();

      if (!allowClick) {
        return;
      }

      nativeFilePicker$(allowMultiple).subscribe((fs: File[]) => onFilesRead(fs));
    };
  };

  const handleMouseEnter = () => {
    if (!allowClick || disable) {
      return;
    }

    return (e: SyntheticEvent) => {
      e.preventDefault();
      setHovering(true);

      if (onHover) {
        onHover(true);
      }
    };
  };

  const handleMouseLeave = () => {
    if (!allowClick || disable) {
      return;
    }
    return (e: SyntheticEvent) => {
      e.preventDefault();
      setHovering(false);

      if (onHover) {
        onHover(false);
      }
    };
  };

  const handleDrop = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();

      if (disable) {
        return;
      }

      setDragging(false);

      filesParser.readDropEvent(e as unknown as DragEvent).subscribe(
        (files: File[]) => onFilesRead(files),
        (err) => console.error(err)
      );
    },
    [disable, onFilesRead]
  );

  const handleDragEnter = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();

      if (disable) {
        return;
      }
      if (dragCounter === 0) {
        setDragCounter(dragCounter + 1);
        setDragging(true);
      }

      if (onDragEnter) {
        onDragEnter(e as unknown as DragEvent);
      }
    },
    [disable, dragCounter, onDragEnter]
  );

  const handleDragLeave = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (disable) {
        return;
      }

      if (dragCounter > 0) {
        setDragCounter(dragCounter - 1);
      }

      if (dragCounter === 0) {
        setDragging(false);

        if (onDragLeave) {
          onDragLeave(e as unknown as DragEvent);
        }
      }
    },
    [disable, dragCounter, onDragLeave]
  );

  const handleDragOver = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  const themeModeContext: IThemeModeContext = useContext(ThemeModeContext);
  const currentThemePalette: Palette = themeModeContext.getCurrentTheme().palette;

  const ForegroundTextComponent: FCWithChildren = (p) => (
    <ForegroundText hovering={hovering} allowClick={allowClick} disabled={disable} $themePalette={currentThemePalette}>
      {p.children}
    </ForegroundText>
  );

  return (
    <Root
      onClick={handleClick()}
      onMouseEnter={handleMouseEnter()}
      onMouseLeave={handleMouseLeave()}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <Container
        hovering={hovering}
        dragging={dragging}
        allowClick={allowClick}
        disabled={disable}
        border={border}
        $themePalette={currentThemePalette}
      >
        {allowClick && hovering && (
          <Foreground>
            <ForegroundTextComponent>Click to select file{allowMultiple ? 's' : ''}</ForegroundTextComponent>
          </Foreground>
        )}
        {!disable && dragging && (
          <Foreground>
            <ForegroundTextComponent>Drop files here!</ForegroundTextComponent>
          </Foreground>
        )}
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {children && (
          <Content dragging={dragging} hovering={hovering}>
            {children}
          </Content>
        )}
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {background && (
          <Background dragging={dragging} hovering={hovering}>
            {background}
          </Background>
        )}
      </Container>
    </Root>
  );
};
