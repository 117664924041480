import { FC, useState, MouseEvent } from 'react';
import { Divider, Popover, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import { BasicWrapper, CalendarWrapper, StyledMonthCalendar, StyledYearCalendar } from '/shared/DateRangePicker/styles';

import { IMonthYearPickerProps } from './types';

const minYear = new Date(0);
minYear.setFullYear(2000);
export const MonthYearPicker: FC<IMonthYearPickerProps> = ({ month, onMonthSelect, onYearSelect, focusHandler }) => {
  const [anchorSelector, setAnchorSelector] = useState<HTMLSpanElement | null>(null);

  const isPopoverOpen = Boolean(anchorSelector);
  const monthSelectorID = isPopoverOpen ? 'selector-popover' : undefined;

  const handleClose = () => {
    setAnchorSelector(null);
    focusHandler((prevState) => ({ open: false, position: prevState.position }));
  };

  const handleMonthSelect = (value: DateTime) => {
    if (value.monthLong) {
      onMonthSelect(month, value.monthLong);
      handleClose();
    }
  };

  const handleYearSelect = (value: DateTime) => {
    onYearSelect(month, `${value.year}`);
    handleClose();
  };

  const handleOpen = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorSelector(event.currentTarget);
    focusHandler((prevState) => ({ open: true, position: prevState.position }));
  };

  return (
    <BasicWrapper>
      <Typography
        aria-haspopup='true'
        variant='subtitle1'
        sx={{ fontWeight: 'bold', cursor: 'pointer' }}
        onClick={handleOpen}
      >
        {month.format('MMMM')} {month.format('YYYY')}
      </Typography>
      <Popover
        id={monthSelectorID}
        open={isPopoverOpen}
        anchorEl={anchorSelector}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CalendarWrapper>
          <StyledMonthCalendar value={DateTime.fromISO(month.toISOString())} onChange={handleMonthSelect} />
          <Divider orientation={'vertical'} flexItem={true} />
          <StyledYearCalendar
            value={DateTime.fromISO(month.toISOString())}
            minDate={DateTime.fromJSDate(minYear)}
            onChange={handleYearSelect}
          />
        </CalendarWrapper>
      </Popover>
    </BasicWrapper>
  );
};
