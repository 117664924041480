import { FC, memo } from 'react';
import { CircularProgress } from '@mui/material';

import { PreviewSwitch } from './PreviewSwitch';
import { IProcessPreview } from './types';

export const ProcessPreview: FC<IProcessPreview> = memo((props) => {
  const { url, loading, spinnersize, Fallback, ...rest } = props;

  if (loading) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return <CircularProgress size={spinnersize} />;
  }

  return url ? <PreviewSwitch url={url} Fallback={Fallback} {...rest} /> : <Fallback />;
});
