import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { Filter } from '@playq/octopus-common';
import { AppEntitiesResponse, AppEntitySort, AppEntityFeatures } from '@playq/octopus2-apps';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Either } from '@playq/irt';

import { useEitherQuery } from '/api';

export const queryVersionsKeys: unknown[] = ['query-versions'];

export type QueryVersions<T> = (
  id: T,
  iterator: OffsetLimit,
  sortBy: AppEntitySort[],
  filterBy: { [key: string]: Filter },
  features: AppEntityFeatures[]
) => Promise<Either<GenericFailure, AppEntitiesResponse>>;

const emptyArr: [] = [];
export function useQueryVersions<T>(
  id: T | undefined,
  iterator: OffsetLimit,
  sortBy: AppEntitySort[],
  filterBy: { [key: string]: Filter },
  queryVersions: QueryVersions<T>,
  options?: UseQueryOptions<AppEntitiesResponse | undefined, GenericFailure | Error>
) {
  const keys = useMemo(
    () => queryVersionsKeys.concat(id, iterator, sortBy, filterBy),
    [id, iterator, sortBy, filterBy]
  );

  const res = useEitherQuery(
    keys,
    () => queryVersions(id as T, iterator, sortBy, filterBy, [AppEntityFeatures.AuthorName]),
    {
      keepPreviousData: true,
      ...options,
      enabled: options?.enabled !== false && id !== undefined,
    }
  );

  return {
    ...res,
    tags: keys,
    versions: res.data?.versions || emptyArr,
    total: res.data?.total ?? 0,
  };
}
