import { useMemo } from 'react';

import { SourceType } from '/shared/FilePreview/types';
import { ProcessPreview } from '/shared/FilePreview/ProcessPreview';

import { IHandlerProps } from '../types';

export function FileHandler({ source, ...rest }: IHandlerProps<File>) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const url = useMemo(() => URL.createObjectURL(source), [source.lastModified]);

  return <ProcessPreview url={url} type={source.type} {...rest} />;
}

FileHandler.canProcess = (source: SourceType): boolean => source instanceof File;
