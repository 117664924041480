import { TimeIterator } from '@playq/octopus-common';

import { DateRange } from '/shared/DateRangePicker';

export const makeNewIterator = (selectedRange: DateRange): TimeIterator => {
  const newIterator = new TimeIterator();
  newIterator.from_ = selectedRange[0] || new Date();
  newIterator.till = selectedRange[1] || new Date();

  return newIterator;
};
