import { DateTime } from 'luxon';

export const getChartEntityBegin = (entityStartDate: Date, calendarTimeRangeFrom: Date): Date =>
  entityStartDate.getTime() > calendarTimeRangeFrom.getTime()
    ? entityStartDate
    : DateTime.fromJSDate(calendarTimeRangeFrom).toJSDate();

export const getChartEntityFinish = (entityEndDate: Date, calendarTimeRangeTill: Date): Date =>
  entityEndDate.getTime() < calendarTimeRangeTill.getTime()
    ? entityEndDate
    : DateTime.fromJSDate(calendarTimeRangeTill).toJSDate();
