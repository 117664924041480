import { FC, SyntheticEvent } from 'react';
import { Dialog, DialogContent, Tooltip, IconButton } from '@mui/material';
import { ChevronRight as NextImageButton, ChevronLeft as PrevImageButton } from '@mui/icons-material';

import { IFallbackProps, IFullPreviewProps, SourceType } from '/shared/FilePreview/types';

import { useFullScreenPreviewStyle } from './styles';
import { handlers, IHandlerProps } from './handlers';
import { UnknownPreview } from './previewComponents';

export const FullScreenPreview: FC<IFullPreviewProps> = (props) => {
  const { source, Fallback, withPagination, showPrev, showNext, onClose, onRenderFailure, ...rest } = props;
  const classes = useFullScreenPreviewStyle();

  const FB: FC<IFallbackProps> = () => {
    return Fallback ? (
      <Fallback />
    ) : (
      <UnknownPreview fallbackTitle={rest.fallbackTitle} onRenderFailure={onRenderFailure} />
    );
  };

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!source) {
    return <FB />;
  }

  const Handler = handlers.find((handler) => handler.canProcess(source)) as FC<IHandlerProps<SourceType>>;

  const isHandlerExist = Handler !== null;

  const handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth='lg' className={classes.dialogContainer}>
      <DialogContent className={classes.content} onClick={handleStopPropagation}>
        {withPagination && (
          <div className={classes.prevPaginationButtonWrapper} onClick={showPrev}>
            <Tooltip placement='top' title='Previous'>
              <IconButton size='medium' className={classes.paginationButton}>
                <PrevImageButton />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {isHandlerExist ? <Handler source={source} autoPlay={true} Fallback={FB} {...rest} /> : <FB />}
        {withPagination && (
          <div className={classes.nextPaginationButtonWrapper} onClick={showNext}>
            <Tooltip placement='top' title='Next'>
              <IconButton size='small' className={classes.paginationButton}>
                <NextImageButton />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
