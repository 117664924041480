import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, useContext } from 'react';
import { BurstMode as BurstModeIcon, PlayCircleOutline } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import { Mimes, PreviewSizes } from '/constants/file';
import { IThemeModeContext } from '/common/models';
import { ThemeModeContext } from '/common';

import { IPreviewComponentProps } from '../types';

import { RebuildThumbIconStyled, useVideoPreviewStyles } from './styles';

export function VideoPreview(props: IPreviewComponentProps) {
  const { url, autoPlay, Fallback, onRebuildThumbnail, ...rest } = props;
  const classes = useVideoPreviewStyles();
  const [error, setError] = useState(false);
  const firstRender = useRef(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playIcon, setPlayIcon] = useState(false);
  const [bounds, setBounds] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    if (videoRef.current) {
      const { width, height } = videoRef.current.getBoundingClientRect();
      setBounds({
        width,
        height,
      });
    }
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setPlayIcon(bounds.height <= PreviewSizes.small && bounds.width <= PreviewSizes.small);
  }, [bounds.height, bounds.width]);

  const controls = useMemo(
    () => bounds.height >= PreviewSizes.mid && bounds.width >= PreviewSizes.mid,
    [bounds.height, bounds.width]
  );

  const handleError = useCallback(() => setError(true), []);

  const handleRebuildThumb = useCallback(() => {
    const second = videoRef.current?.currentTime;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!second) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      onRebuildThumbnail();
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    onRebuildThumbnail(second);
  }, [onRebuildThumbnail]);

  const handleCanPlay = () => {
    if (videoRef.current) {
      const { width, height } = videoRef.current.getBoundingClientRect();
      setBounds({
        width,
        height,
      });
    }
  };

  const themeModeContext: IThemeModeContext = useContext(ThemeModeContext);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      {playIcon && <PlayCircleOutline onClick={rest?.onClick} className={classes.playButton} />}

      {!playIcon &&
        (error ? (
          <Fallback fallbackTitle='Video is invalid' {...rest} />
        ) : (
          <>
            {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
            {onRebuildThumbnail && (
              <Tooltip title='Build thumbnail on video player position'>
                <RebuildThumbIconStyled
                  onClick={handleRebuildThumb}
                  $theme={themeModeContext.getCurrentTheme()}
                  size='small'
                >
                  <BurstModeIcon fontSize='small' />
                </RebuildThumbIconStyled>
              </Tooltip>
            )}

            <div className={classes.videoContainer}>
              <video
                className={classes.video}
                onCanPlay={handleCanPlay}
                ref={videoRef}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                autoPlay={autoPlay}
                onError={handleError}
                controls={controls}
                {...rest}
              >
                <source src={url} />
              </video>
            </div>
          </>
        ))}
    </>
  );
}

VideoPreview.mimes = Mimes.Video;
