/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { ComponentProps } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme, styled } from '@mui/material';
import { Spacing } from '@mui/system';
import { grey } from '@mui/material/colors';

export const ThumbnailPreviewContainerStyled = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: inherit;
  max-width: inherit;
  cursor: pointer;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FilePreviewContainerStyled = styled(Box)<ComponentProps<any>>`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: inherit;
  max-width: inherit;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const TooltipContainerStyled = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const paginationButtonWrapperStyles = {
  top: 0,
  width: '50%',
  height: '100%',
  display: 'flex',
  cursor: 'pointer',
  opacity: 0,
  '&:hover ': {
    opacity: 1,
    transitionDuration: '1s',
  },
};

const NAV_BUTTON_WRAPPER_MARGIN = 30;
const NAV_BUTTON_SIZE = 40;
const CONTENT_MIN_SIZE = 100;

export const useFullScreenPreviewStyle = makeStyles((theme: Theme) => {
  const themeSpacing: Spacing = theme.spacing.bind(theme);

  return {
    dialogContainer: {
      margin: themeSpacing(4),
      '& .MuiDialog-paperWidthLg': {
        display: 'grid',
        alignContent: 'center',
      },
    },
    content: {
      display: 'flex',
      minHeight: CONTENT_MIN_SIZE,
      minWidth: CONTENT_MIN_SIZE,
      maxWidth: `calc(100vw - ${themeSpacing(22)}px)`,
      maxHeight: `calc(100vh - ${themeSpacing(22)}px)`,
      width: 'auto',
      height: 'auto',
      overflowY: 'hidden',
      backgroundColor: theme.palette.background.default,
      justifyContent: 'center',
      padding: 0,
      border: 0,
      // this is needed to override default MuiDialogContent styles
      '&:first-child': {
        padding: 0,
      },
    },
    paginationButton: {
      width: NAV_BUTTON_SIZE,
      height: NAV_BUTTON_SIZE,
      alignSelf: 'center',
      borderRadius: '100%',
      border: `1px solid ${grey[500]}`,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: grey[500],
      },
    },
    prevPaginationButtonWrapper: {
      ...paginationButtonWrapperStyles,
      position: 'absolute',
      left: NAV_BUTTON_WRAPPER_MARGIN,
    },
    nextPaginationButtonWrapper: {
      ...paginationButtonWrapperStyles,
      position: 'absolute',
      right: NAV_BUTTON_WRAPPER_MARGIN,
      justifyContent: 'flex-end',
    },
  };
});
