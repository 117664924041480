import { makeStyles } from '@mui/styles';
import { Palette, Theme } from '@mui/material';
import { Spacing } from '@mui/system';

import { ThemeProvider } from '/common';
import { ThemeMode } from '/common/models';

export const useStyles = makeStyles((theme: Theme) => {
  const themePalette: Palette = theme.palette;
  const themeSpacing: Spacing = theme.spacing.bind(theme);

  return {
    block: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      padding: `${themeSpacing(2)}`,
    },
    error: {
      borderColor: theme.palette.error.main,
    },
    label: {
      padding: '0 5px',
      backgroundColor: theme.palette.background.paper,
      textAlign: 'center',
      color: ThemeProvider.getAppropriateStyles(
        themePalette.mode as ThemeMode,
        themePalette.primary.main,
        themePalette.text.primary
      ),
      position: 'absolute',
      top: `-${themeSpacing(1.5)}`,
      left: `${themeSpacing(2)}`,
      height: `${themeSpacing(2)}`,
    },
    errorLabel: {
      display: 'flex',
      color: theme.palette.error.main,
    },
    button: {
      width: `${themeSpacing(4)}`,
      height: `${themeSpacing(4)}`,
      minHeight: `${themeSpacing(4)}`,
      marginLeft: 10,
    },
    buttonActions: {
      zIndex: 10,
    },
    blockActions: {
      position: 'absolute',
      top: `-${themeSpacing(2)}`,
      right: `${themeSpacing(1)}`,
    },
    spinner: {
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      right: '-1px',
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
  };
});
