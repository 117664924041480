import { useCallback, useRef, useState } from 'react';

import { AppEntityVersionResponse } from '@playq/octopus2-apps';

import { AppEntityVersionPicker } from './AppEntityVersionPicker';
import { IUseVersionPickerResult, IVersionPickerProps } from './types';

export function useVersionPicker<T>(): IUseVersionPickerResult<T> {
  const [props, setProps] = useState<IVersionPickerProps<T> | undefined>();
  const [show, setShow] = useState(false);
  const resolveRef = useRef<((value?: AppEntityVersionResponse) => void) | undefined>(undefined);

  const handleSelect = useCallback((version: AppEntityVersionResponse) => {
    resolveRef.current?.(version);
    setShow(false);
  }, []);

  const handleClose = useCallback(() => {
    resolveRef.current?.();
    setShow(false);
  }, []);

  const pickVersion = (newProps: IVersionPickerProps<T>) => {
    setProps(newProps);
    setShow(true);

    return new Promise<AppEntityVersionResponse | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const node = props && (
    <AppEntityVersionPicker<T> {...props} open={show} onEntitySelect={handleSelect} onClose={handleClose} />
  );

  return [node, pickVersion] as const;
}
