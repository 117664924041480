import { FC, memo } from 'react';

import { parsePath, Target } from './helpers';
import { previewComponents, UnknownPreview } from './previewComponents';
import { IPreviewSwitch } from './types';

export const PreviewSwitch: FC<IPreviewSwitch> = memo((props) => {
  const { url, Fallback, RenderComponent, type, ...rest } = props;
  const fileType = type || parsePath(url, Target.Type);

  if (!fileType) {
    return <Fallback />;
  }

  if (RenderComponent) {
    return <RenderComponent url={url} {...rest} Fallback={Fallback} />;
  }

  const mime = previewComponents.find((c) => c.mimes.includes(fileType));
  const Component = mime || UnknownPreview;

  return <Component url={url} Fallback={Fallback} {...rest} />;
});
