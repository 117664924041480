import { FC } from 'react';
import { Button, Dialog, DialogContent } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

import { FileRevisionID, FileID } from '@playq/octopus2-files';

import { nativeFilePicker$ } from '/helpers';
import { Dropzone } from '/shared/Dropzone';

import useStyles from './styles';

export interface IFilesPickDialogProps extends Omit<DialogProps, 'children'> {
  fileRevisionID?: FileRevisionID;
  retryFileID?: FileID;
  onFilesRead: (fs: File[], fileRevisionID?: FileRevisionID, fileID?: FileID) => void;
  onClose: () => void;
}

export const FilesPickDialog: FC<IFilesPickDialogProps> = (props) => {
  const { open, onFilesRead, onClose, fullWidth = true, maxWidth = 'lg', fileRevisionID, retryFileID } = props;

  const classes = useStyles();

  const handleFilePick = () => {
    nativeFilePicker$(true).subscribe((fs: File[]) => onFilesRead(fs, fileRevisionID, retryFileID));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogContent className={classes.root}>
        <Dropzone onFilesRead={onFilesRead} border={true}>
          <div className={classes.content}>
            <h2 className={classes.title}>Drop files here</h2>
            <p>Or, if you prefer...</p>

            <Button
              variant='outlined'
              color='primary'
              className={classes.actionButton}
              onClick={handleFilePick}
              data-testid='button-select-file'
            >
              Select files from you computer
            </Button>
          </div>
        </Dropzone>
      </DialogContent>
    </Dialog>
  );
};
