import { FC } from 'react';
import { FabProps } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { relativeCreateURL } from '/constants';

import { StyledFab } from './styles';

type FabChildrenType = FabProps['children'];

interface ICreateButtonProps extends Omit<FabProps, 'children'> {
  children?: FabChildrenType;
}

export const CreateButton: FC<ICreateButtonProps> = ({
  color = 'primary',
  children = <AddIcon />,
  href = relativeCreateURL,
  ...restProps
}) => (
  <StyledFab {...restProps} href={href} color={color}>
    {children}
  </StyledFab>
);
