import { SyntheticEvent, useState, useMemo, useCallback } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Environment, TextFilter } from '@playq/octopus-common';
import {
  AppEntityVersionResponse,
  AppEntitySort,
  AppEntitySortField,
  AppEntitySortFieldHelpers,
  AppEntityFilterFieldHelpers,
  AppEntityFilterField,
} from '@playq/octopus2-apps';
import { OffsetLimit } from '@playq/services-shared';

import { formatDate } from '/helpers';
import { useQueryPromotions } from '/api/hooks/promotions';
import {
  CollectionTable,
  ICollectionTableColumn,
  ICollectionTableQuery,
  ICollectionTableQueryChange,
} from '/shared/Table';
import { LabelThemed } from '/shared/LabelThemed';
import { entitiesLimit } from '/constants';

import { IAppEntityVersionPickerProps, IQueryChange } from './types';
import { useQueryVersions } from './useQueryVersions';

const useClasses = makeStyles({
  dialogContent: {
    minHeight: '425px',
  },
});

const AppEntityVersionTable = CollectionTable.ofType<
  AppEntityVersionResponse,
  AppEntitySortField,
  AppEntityFilterField,
  AppEntitySort
>();

function VersionPicker<T>(props: IAppEntityVersionPickerProps<T>) {
  const {
    open,
    entityId,
    version,
    showCurrentVersionLabel = true,
    avoidCurrentVersionSelection = true,
    customCommentColumnMeta,
    columns: propColumns,
    onEntitySelect,
    queryVersions,
    queryPromotions,
    onClose,
  } = props;
  const [query, setQuery] = useState<ICollectionTableQuery<AppEntitySort>>({
    iterator: new OffsetLimit({ limit: entitiesLimit, offset: 0 }),
    filterBy: {},
    sortBy: [],
  });

  const [enableQueryVersions, setEnableQueryVersions] = useState<boolean>(true);

  const {
    versions,
    total,
    isLoading,
    errorMessage,
    tags: queryKeys,
    refetch,
  } = useQueryVersions(entityId, query.iterator, query.sortBy, query.filterBy, queryVersions, {
    enabled: enableQueryVersions,
  });
  const promotionsResponse = useQueryPromotions(entityId, [], queryPromotions);

  const classes = useClasses();

  const columns: ICollectionTableColumn<AppEntityVersionResponse, AppEntitySortField, AppEntityFilterField>[] = useMemo(
    () => [
      {
        label: 'Version',
        sort: AppEntitySortField.Version,
        filter: AppEntityFilterField.Version,
        filterType: TextFilter.ClassName,
        render: (ent: AppEntityVersionResponse) => {
          const shouldShowCurrentVersionLabel = ent.version === version && showCurrentVersionLabel;
          const isLive =
            promotionsResponse.data?.live !== undefined && promotionsResponse.data.live.version === ent.version;
          return (
            <>
              <span>{ent.version}</span>
              {shouldShowCurrentVersionLabel && <LabelThemed>Current</LabelThemed>}
              {isLive && <LabelThemed env={Environment.Live}>Live</LabelThemed>}
            </>
          );
        },
      },
      {
        label: 'UpdatedAt',
        sort: AppEntitySortField.UpdatedAt,
        filterType: TextFilter.ClassName,
        render: (ent: AppEntityVersionResponse) => formatDate(ent.updatedAt),
      },
      {
        label: 'Author',
        sort: AppEntitySortField.Author,
        filter: AppEntityFilterField.Author,
        filterType: TextFilter.ClassName,
        render: (ent: AppEntityVersionResponse) => ent.authorName,
      },
      {
        label: 'Comment',
        filter: AppEntityFilterField.Comment,
        filterType: TextFilter.ClassName,
        render: customCommentColumnMeta?.render
          ? customCommentColumnMeta.render.bind(null, customCommentColumnMeta.customParam)
          : (ent: AppEntityVersionResponse) => ent.comment,
      },
      ...(propColumns ?? []),
    ],
    [
      showCurrentVersionLabel,
      promotionsResponse.data?.live,
      version,
      customCommentColumnMeta?.render,
      customCommentColumnMeta?.customParam,
      propColumns,
    ]
  );

  const handleSelect = useCallback(
    (e: SyntheticEvent, v: AppEntityVersionResponse) => {
      e.stopPropagation();

      if (v.version === version && avoidCurrentVersionSelection) {
        return;
      }

      onEntitySelect(v);
      onClose();
    },
    [avoidCurrentVersionSelection, onClose, onEntitySelect, version]
  );

  const handleQueryChange = (param: ICollectionTableQueryChange<IQueryChange>) => {
    setQuery(param.query);
  };

  return (
    <Dialog fullWidth={true} open={open} maxWidth='md' onClose={onClose}>
      <DialogTitle>Select Version</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <AppEntityVersionTable
          sortHelper={AppEntitySortFieldHelpers}
          filterHelper={AppEntityFilterFieldHelpers}
          sortClass={AppEntitySort}
          columns={columns}
          data={versions}
          total={total}
          error={errorMessage}
          initialQuery={query}
          queryKeys={queryKeys}
          onQueryChange={handleQueryChange}
          processing={isLoading}
          contentProcessing={false}
          placeholder={{
            text: 'There is no AppEntityVersions yet.',
          }}
          refetch={refetch}
          onRowClick={handleSelect}
          setEnableQueryEntities={setEnableQueryVersions}
        />
      </DialogContent>
    </Dialog>
  );
}

export function AppEntityVersionPicker<T>(props: IAppEntityVersionPickerProps<T>) {
  return (
    <Dialog fullWidth={true} open={props.open} maxWidth='md' onClose={props.onClose}>
      <DialogTitle>Select Version</DialogTitle>
      <VersionPicker {...props} />
    </Dialog>
  );
}
