import { makeStyles } from '@mui/styles';
import { Palette, Theme } from '@mui/material';

import { ThemeMode } from '/common/models';

export default makeStyles((theme: Theme) => {
  const themePalette: Palette = theme.palette;
  const textPrimaryColor: string = themePalette.text.primary;
  const darkThemeActionButtonStyles = {
    color: textPrimaryColor,
    borderColor: themePalette.text.secondary,

    '&:hover': {
      borderColor: textPrimaryColor,
      backgroundColor: themePalette.action.hover,
    },
  };

  return {
    root: {
      height: '500px',
      padding: 24,
    },
    content: {
      display: 'grid',
      alignContent: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: '100%',
      width: '100%',
    },
    actionButton: {
      marginTop: 10,
      ...(themePalette.mode === ThemeMode.Dark ? darkThemeActionButtonStyles : null),
    },
    title: {
      color: themePalette.secondary.main,
    },
  };
});
