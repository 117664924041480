import { FC } from 'react';
import { Divider, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { DateTime } from 'luxon';

import { ListStyledWrapper, TimePresetsWrapper } from '/shared/DateRangePicker/styles';

import { ITimePresetsProps } from './types';

export const TimePresets: FC<ITimePresetsProps> = ({ hideFuture, hidePast, pickerHeight, onChange }) => {
  const presetsPast = [
    {
      name: 'Past Year',
      method: () =>
        onChange({
          startDate: DateTime.now().minus({ years: 1 }).plus({ day: 1 }).startOf('day'),
          endDate: DateTime.now().endOf('day'),
        }),
    },
    {
      name: 'Past Month',
      method: () =>
        onChange({
          startDate: DateTime.now().minus({ months: 1 }).plus({ day: 1 }).startOf('day'),
          endDate: DateTime.now().endOf('day'),
        }),
    },
    {
      name: 'Past Week',
      method: () =>
        onChange({
          startDate: DateTime.now().minus({ weeks: 1 }).plus({ day: 1 }).startOf('day'),
          endDate: DateTime.now().endOf('day'),
        }),
    },
    {
      name: 'Yesterday',
      method: () => {
        const today = DateTime.now().startOf('day');
        onChange({
          startDate: today.minus({ days: 1 }).startOf('day'),
          endDate: today.minus({ days: 1 }).endOf('day'),
        });
      },
    },
  ];

  const presetsPresent = [
    {
      name: 'Today',
      method: () =>
        onChange({
          startDate: DateTime.now().startOf('day'),
          endDate: DateTime.now().endOf('day'),
        }),
    },
  ];

  const presetsFuture = [
    {
      name: 'Tomorrow',
      method: () => {
        onChange({
          startDate: DateTime.now().plus({ days: 1 }).startOf('day'),
          endDate: DateTime.now().plus({ days: 1 }).endOf('day'),
        });
      },
    },
    {
      name: 'Next Week',
      method: () => {
        onChange({
          startDate: DateTime.now().startOf('day'),
          endDate: DateTime.now().plus({ weeks: 1 }).minus({ day: 1 }).endOf('day'),
        });
      },
    },
    {
      name: 'Next Month',
      method: () => {
        onChange({
          startDate: DateTime.now().startOf('day'),
          endDate: DateTime.now().plus({ months: 1 }).minus({ day: 1 }).endOf('day'),
        });
      },
    },
    {
      name: 'Next Year',
      method: () => {
        onChange({
          startDate: DateTime.now().startOf('day'),
          endDate: DateTime.now().plus({ years: 1 }).minus({ day: 1 }).endOf('day'),
        });
      },
    },
  ];

  const getPresets = () => {
    if (hidePast) {
      return presetsPresent.concat(presetsFuture);
    }

    if (hideFuture) {
      return presetsPast.concat(presetsPresent);
    }

    return presetsPast.concat(presetsPresent, presetsFuture);
  };

  return (
    <TimePresetsWrapper>
      <ListStyledWrapper dense={true} height={pickerHeight}>
        {getPresets().map((preset) => {
          return (
            <ListItem sx={{ padding: 0 }} key={preset.name} disableGutters={true}>
              <ListItemButton onClick={preset.method}>
                <ListItemText primary={preset.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </ListStyledWrapper>
      <Divider orientation={'vertical'} flexItem={true} />
    </TimePresetsWrapper>
  );
};
