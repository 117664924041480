import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useDatePickerStyles = makeStyles<Theme>(() => ({
  timePicker: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
    '& .MuiInputBase-input': { height: 8, width: '85px' },
  },
}));
