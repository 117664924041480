import { FC, memo, useMemo } from 'react';

import { FileRevisionID } from '@playq/octopus2-files';

import { IFallbackProps, ISourcePreviewProps, SourceType } from './types';
import { UnknownPreview } from './previewComponents';
import { handlers, IHandlerProps } from './handlers';
import { FilePreviewContainerStyled } from './styles';

export const SourcePreview: FC<ISourcePreviewProps> = memo((props) => {
  const { missed = false, source, Fallback, fallbackTitle = `Can't preview file`, onRenderFailure, ...rest } = props;

  const FB: FC<IFallbackProps> = () => {
    return Fallback ? <Fallback /> : <UnknownPreview fallbackTitle={fallbackTitle} onRenderFailure={onRenderFailure} />;
  };

  const sourceDep =
    source instanceof File ? source.lastModified : source instanceof FileRevisionID ? source.toString() : source;
  const Handler = useMemo(
    () =>
      source !== undefined && missed === false
        ? (handlers.find((handler) => handler.canProcess(source)) as FC<IHandlerProps<SourceType>>)
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceDep, missed]
  );

  if (source === undefined || missed !== false) {
    return <FB />;
  }

  return (
    <FilePreviewContainerStyled {...rest}>
      {Handler ? <Handler source={source} Fallback={FB} {...rest} /> : <FB />}
    </FilePreviewContainerStyled>
  );
});
