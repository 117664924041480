import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  market: {
    textTransform: 'none',
    display: 'grid',
    columnGap: theme.spacing(3),
    gridTemplateColumns: 'minmax(270px, 1fr) 1fr',
    padding: 0,
    marginLeft: 0,
    minHeight: `50px`,
  },
  marketIconSwitch: {
    display: 'grid',
    columnGap: theme.spacing(3),
    gridTemplateColumns: '50px 35px auto',
    alignItems: 'center',
  },
  marketIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 50,
    backgroundColor: 'transparent',
    border: `2px solid`,
    overflow: 'hidden',
    borderRadius: '50%',
    outline: 'none',
    color: theme.palette.divider,
  },
  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  marketIconSelected: {
    cursor: 'pointer',
    borderColor: theme.palette.secondary.main,
  },
  marketIconEnabled: {
    cursor: 'pointer',
    borderColor: theme.palette.text.disabled,
  },
}));
