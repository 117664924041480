import { makeStyles } from '@mui/styles';
import { List, styled, Theme } from '@mui/material';
import { MonthCalendar, YearCalendar } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';

import { ThemeMode } from '/common/models';
import { ThemeProvider } from '/common';

import { UseDateRangePickerStylesArgs } from './types';

export const MEDIUM_DATE_RANGE_PICKER_HEIGHT = 46;
export const LARGE_DATE_RANGE_PICKER_HEIGHT = 56;
export const TIME_PRESETS_WIDTH = 150;

export const useStyles = makeStyles<Theme>(() => ({
  input: {
    background: 'red',
    color: 'red',
    border: `2px solid red`,
  },
}));

const getInputHeightDepengingOnSize = ({ size }: UseDateRangePickerStylesArgs) => {
  if (size === 'small') {
    return 'inherit';
  }
  if (size === 'medium') {
    return MEDIUM_DATE_RANGE_PICKER_HEIGHT;
  }
  if (size === 'large') {
    return LARGE_DATE_RANGE_PICKER_HEIGHT;
  }
};

const overridePrimaryLightMode = 'rgba(25, 118, 210, 0.12)';
const overridePrimaryDarkMode = 'rgba(144, 202, 249, 0.12)';

export const useDateRangePickerStyles = makeStyles<Theme, UseDateRangePickerStylesArgs>((theme) => {
  const borderColor = theme.palette.grey[400];
  const disabledOpacity = theme.palette.action.disabledOpacity;

  const getColorDependingOnError = (defaultColor?: string) => (props: UseDateRangePickerStylesArgs) =>
    props.error ? theme.palette.error.main : (defaultColor ?? theme.palette.text.primary);

  const overridePrimaryLight = ThemeProvider.getAppropriateStyles(
    theme.palette.mode as ThemeMode,
    overridePrimaryLightMode,
    overridePrimaryDarkMode
  );

  return {
    label: (props) => ({
      ...(props.isMaterialStyled
        ? {
            padding: '0 5px',
            textAlign: 'center',
            position: 'absolute',
            top: '-6px',
            left: '6px',
            height: '14px',
            lineHeight: '12px',
            fontSize: '12px',
            pointerEvents: 'none',
            zIndex: 1,
            backgroundColor: theme.palette.background.paper,
          }
        : {}),
      ...(props.error
        ? {
            color: theme.palette.error.main,
          }
        : {}),
    }),
    timePickerWrapper: {
      display: 'flex',
      position: 'absolute',
      right: 0,
      bottom: 0,
      zIndex: 99,
      justifyContent: 'space-around',
      padding: theme.spacing(3),
      paddingTop: 0,

      width: (props) => `calc(100% - ${props.singleMonth ? 0 : TIME_PRESETS_WIDTH}px)`,
    },
    timePicker: {
      '& .MuiInputBase-input': { height: 8, width: '85px' },
    },
    picker: {
      position: 'relative',

      '&.unique_date_range_picker': {
        position: 'static',
      },

      '& .DateRangePicker, & .DateRangePickerInput': {
        height: getInputHeightDepengingOnSize,
        width: '100%',
        borderWidth: (props) => (props.error ? 1.2 : 1),
        borderColor: getColorDependingOnError(borderColor),
      },
      '& .DateRangePickerInput': {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        paddingRight: theme.spacing(2),
        background: theme.palette.background.paper,
      },
      '& .DateInput, & .CalendarMonthGrid, & .CalendarMonth, & .CalendarMonth_caption, & .DayPickerNavigation_button, & .CalendarDay':
        {
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
        },
      '& .DateInput': {
        width: 'fit-content',
      },
      '& .DateRangePickerInput_calendarIcon_svg': {
        fill: getColorDependingOnError(borderColor),
      },
      '& .DateRangePickerInput_calendarIcon': {
        marginRight: '0',
      },
      '& .DateInput_input': {
        background: theme.palette.background.paper,
        minWidth: '100px',
        color: getColorDependingOnError(),
        outline: 'none',
      },
      '& .DateInput_input:disabled': {
        opacity: disabledOpacity,
      },
      '& .DateInput_input__focused': {
        borderColor: theme.palette.primary.main,
      },
      '& .DateInput_fang': {
        zIndex: theme.zIndex.modal + 1,
        // todo align fang with DateRangePicker_picker
        display: 'none',
      },
      '& .DayPickerNavigation_button__default, .DayPickerNavigation_button__default:hover, .DayPickerNavigation_button__default:focus ':
        {
          border: 'none',
          top: '20px',
        },
      '& .DayPickerNavigation_button__default:active': {
        background: theme.palette.background.default,
      },
      '& .DayPickerNavigation_button__default:hover .DayPickerNavigation_svg__horizontal': {
        fill: theme.palette.primary.light,
      },
      '& .DateInput_fangShape': {
        fill: theme.palette.background.paper,
      },
      '& .DateInput_fangStroke': {
        stroke: theme.palette.divider,
      },
      '& .DateRangePickerInput_arrow': {
        color: getColorDependingOnError(),
      },
      '& .DateRangePicker_picker': {
        marginTop: (props) => (props.size === 'medium' ? 11 : 2),
        marginBottom: (props) => (props.openDirection === 'up' && props.size === 'medium' ? 11 : 2),
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        zIndex: theme.zIndex.drawer + 1,
        borderRadius: '4px',
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,' +
          'rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,' +
          'rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
      },
      '& .DayPicker': {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
      },
      '& .CalendarMonth_table': {
        borderCollapse: 'separate',
        borderSpacing: '0 2px',
      },
      '& .CalendarDay': {
        border: 'none',
        padding: '1px',
      },
      '& .CalendarDay__selected, & .CalendarDay__selected_span, & .CalendarDay:hover': {
        borderColor: theme.palette.primary.contrastText,
        background: theme.palette.background.paper,
      },
      '& .CalendarDay__selected': {
        borderRadius: '50%',
        color: theme.palette.primary.contrastText,
      },
      '& tr > .CalendarDay__selected_span:last-of-type, .CalendarDay__lastDayOfWeek.CalendarDay__hovered_span': {
        borderRadius: '0 50% 50% 0 !important',
      },
      '& .CalendarDay__selected_span:first-of-type, .CalendarDay__firstDayOfWeek.CalendarDay__hovered_span': {
        borderRadius: '50% 0 0 50% !important',
      },
      '& td:empty + td': {
        borderRadius: '50% 0 0 50%',
      },
      '& td:empty + .CalendarDay__selected_end': {
        borderRadius: '50%',

        '&:after': {
          display: 'none',
        },
      },
      '& tr:last-child > .CalendarDay__selected_span': {
        position: 'relative',
        zIndex: '2',
      },
      '& tr:last-child > .CalendarDay__selected_span + td:empty': {
        position: 'relative',
        zIndex: '1',

        '&:before': {
          content: '" "',
          zIndex: '-1',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '20px',
          height: '100%',
          borderRadius: '0 55px 55px 0',
          background: overridePrimaryLight,
        },
      },
      '& tr:last-child > td:empty + td': {
        position: 'relative',
        zIndex: '1',

        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
      '& .CalendarDay__selected_start': {
        borderRadius: '50%',
        position: 'relative',
        zIndex: '1',

        '&:after': {
          content: '" "',
          zIndex: '-2',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '50% 0 0 50%',
          background: overridePrimaryLight,
        },

        '&:before': {
          content: '" "',
          zIndex: '-1',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          background: theme.palette.primary.main,
        },
      },
      '& .CalendarDay__selected_end, .CalendarDay__hovered_span:hover': {
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
        position: 'relative',
        zIndex: '1',

        '&:after': {
          content: '" "',
          zIndex: '-2',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '0 50% 50% 0',
          background: overridePrimaryLight,
        },

        '&:before': {
          content: '" "',
          zIndex: '-1',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          background: theme.palette.primary.main,
        },
      },
      '& .CalendarDay__selected_start.CalendarDay__selected_end, .CalendarDay__selected_start_no_selected_end:hover': {
        '&:after': {
          content: '" "',
          display: 'none',
        },
      },
      '& .CalendarDay__lastDayOfWeek.CalendarDay__selected_start, .CalendarDay__firstDayOfWeek.CalendarDay__selected_end':
        {
          background: overridePrimaryLight,
          borderRadius: '50%',
          position: 'relative',
          zIndex: '1',

          '&:after': {
            content: '" "',
            display: 'none',
          },

          '&:before': {
            content: '" "',
            zIndex: '-1',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            background: theme.palette.primary.main,
          },
        },
      '& .CalendarDay__today': {
        textDecoration: 'underline',
      },
      '& .CalendarDay__selected_span': {
        background: overridePrimaryLight,
        borderRadius: 0,
      },
      '& .CalendarDay__hovered_span': {
        color: theme.palette.text.primary,
        background: overridePrimaryLight,
        borderRadius: 0,
      },
      '& .CalendarDay:hover': {
        background: theme.palette.background.paper,
        border: 'none',
        position: 'relative',
        zIndex: '1',

        '&:before': {
          content: '" "',
          zIndex: '1',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          border: `1px dashed ${theme.palette.action.disabled}`,
          background: 'none',
        },
      },
      '& .CalendarDay__selected_span:hover, &.CalendarDay__selected_span:hover': {
        background: overridePrimaryLight,
        border: 'none',
        position: 'relative',
        zIndex: '1',
        color: theme.palette.text.primary,

        '&:after': {
          content: '" "',
          zIndex: '1',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          background: overridePrimaryLight,
        },

        '&:before': {
          content: '" "',
          display: 'none',
        },
      },
      '& .CalendarDay.CalendarDay__selected_start:hover, &.CalendarDay__selected_start_in_hovered_span:hover': {
        color: theme.palette.primary.contrastText,
        background: overridePrimaryLight,
        position: 'relative',
        borderRadius: '50%',
        zIndex: '1',

        '&:after': {
          content: '" "',
          zIndex: '-2',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '50% 0  0 50%',
          background: overridePrimaryLight,
        },

        '&:before': {
          content: '" "',
          zIndex: '-1',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          background: theme.palette.primary.main,
          border: 'none',
          opacity: 0.5,
        },
      },
      '& .CalendarDay.CalendarDay__selected_end:hover, &.CalendarDay__selected_end_in_hovered_span:hover': {
        color: theme.palette.primary.contrastText,
        background: overridePrimaryLight,
        position: 'relative',
        zIndex: '1',

        '&:after': {
          content: '" "',
          zIndex: '-2',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '0  50% 50% 0',
          background: overridePrimaryLight,
        },

        '&:before': {
          content: '" "',
          zIndex: '-1',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          background: theme.palette.primary.main,
          border: 'none',
          opacity: 0.5,
        },
      },
    },
  };
});

export const StyledMonthCalendar = styled(MonthCalendar<DateTime>)`
  width: 250px;
`;

export const StyledYearCalendar = styled(YearCalendar<DateTime>)`
  width: 180px;
  max-height: 250px;

  .MuiPickersYear-root {
    flex-basis: 50%;
  }
`;

export const BasicWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;

export const CalendarWrapper = styled('div')`
  display: flex;
  justify-content: center;
  max-height: 250px;
`;

export const TimePresetsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;

  ${() => `
     width: ${`${TIME_PRESETS_WIDTH}px`};
  `}
`;

export const ListStyledWrapper = styled(List)<{ height: number }>`
  width: 100%;

  ${(props) => `
     height: ${`${props.height}px`};

  `}
`;
