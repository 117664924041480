import { useState } from 'react';

import { Spinner } from '/shared/Spinner';
import { Mimes } from '/constants/file';

import { IPreviewComponentProps } from '../types';

import { ImageStyled } from './styles';

export function ImagePreview({ url, Fallback, onRebuildThumbnail: _, ...rest }: IPreviewComponentProps) {
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);
  const handleError = () => setError(true);

  const handleLoaded = () => {
    setLoading(false);
  };

  const handleLoadStart = () => {
    setLoading(true);
  };

  return error ? (
    <Fallback fallbackTitle='Image is invalid' {...rest} />
  ) : (
    <>
      {loading && <Spinner centered={true} />}
      <ImageStyled
        style={{ display: loading ? 'none' : 'block' }}
        src={url}
        onLoadStart={handleLoadStart}
        onLoad={handleLoaded}
        alt={error ? 'failure' : 'image'}
        {...rest}
        onError={handleError}
      />
    </>
  );
}

ImagePreview.mimes = Mimes.Image;
