import { ComponentProps, FC, Fragment, memo, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';

import { PresignedUrl, FileRevisionID, CheckFileRequest, FileState } from '@playq/octopus2-files';
import { GenericFailureCode, GenericFailure } from '@playq/services-shared';

import { UnknownPreview } from '/shared/FilePreview/previewComponents';
import { useCheckFile } from '/api';

import { IFallbackProps, IFilePreviewProps } from './types';
import { ThumbnailPreview } from './ThumbnailPreview';
import { SourcePreview } from './SourcePreview';
import { FullScreenPreview } from './FullScreenPreview';
import { TooltipContainerStyled } from './styles';

export const FilePreview: FC<IFilePreviewProps> = memo((props) => {
  const {
    source,
    missed: propsMissed,
    thumbnails,
    tooltip,
    showTooltip = true,
    Fallback,
    allowFullPreview = true,
    tooltipPlacement = 'bottom',
    isPackage = false,
    shouldCheck = false,
    onClick,
    onRenderFailure,
    onRebuildThumbnail,
    onCheck,
    ...rest
  } = props;

  const [fullPreview, setFullPreview] = useState(false);
  const [failed, setFailed] = useState(false);
  const [missed, setMissed] = useState<boolean | 'checking'>('checking');

  const handleRenderFailure = useCallback(() => {
    if (missed !== 'checking') {
      setFailed(true);
    }

    if (onRenderFailure) {
      onRenderFailure();
    }
  }, [missed, onRenderFailure]);

  const FB: FC<IFallbackProps> = () => {
    return Fallback ? (
      <Fallback />
    ) : (
      <UnknownPreview fallbackTitle={rest.fallbackTitle} onRenderFailure={handleRenderFailure} isPackage={isPackage} />
    );
  };

  const check: CheckFileRequest | undefined = useMemo(() => {
    if (!shouldCheck) {
      setMissed(propsMissed ?? false);
      return undefined;
    }
    if (propsMissed !== undefined) {
      setMissed(propsMissed);
      return undefined;
    }
    if (typeof source === 'string') {
      return new PresignedUrl({ url: source });
    }
    if (source instanceof FileRevisionID) {
      return source;
    }
  }, [shouldCheck, propsMissed, source]);

  useCheckFile(
    { check },
    {
      enabled: !!check,
      onSuccess: (response) => {
        if (response) {
          setMissed(response.state === FileState.NotFound);
          onCheck?.(response.state);
        }
      },
      onError: (err) => {
        if (err instanceof GenericFailure && err.code === GenericFailureCode.EntityNotFound) {
          setMissed(true);
          onCheck?.(FileState.NotFound);
        }
      },
    }
  );

  const handleDialogOpen = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (onClick !== undefined) {
        onClick(event);
      }
      if (!failed && missed === false && allowFullPreview) {
        setFullPreview(true);
      }
    },
    [failed, missed, onClick, allowFullPreview]
  );

  const handleDialogClose = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      if (allowFullPreview) {
        setFullPreview(false);
      }
    },
    [allowFullPreview, setFullPreview]
  );

  const Wrapper =
    failed || missed !== false
      ? Fragment
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ children }: ComponentProps<any>) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return showTooltip ? (
            <Tooltip placement={tooltipPlacement} title={tooltip || 'Click to full preview'}>
              {children}
            </Tooltip>
          ) : (
            children
          );
        };

  return (
    <>
      <Wrapper>
        <TooltipContainerStyled>
          {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
          {thumbnails?.length ? (
            <ThumbnailPreview
              {...rest}
              missed={missed}
              thumbnails={thumbnails}
              Fallback={FB}
              onClick={handleDialogOpen}
            />
          ) : (
            <SourcePreview {...rest} missed={missed} source={source} Fallback={FB} onClick={handleDialogOpen} />
          )}
        </TooltipContainerStyled>
      </Wrapper>

      {fullPreview && (
        <FullScreenPreview
          {...rest}
          source={source}
          onRenderFailure={onRenderFailure}
          Fallback={FB}
          onRebuildThumbnail={onRebuildThumbnail}
          onClose={handleDialogClose}
        />
      )}
    </>
  );
});
