/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any */
import { forwardRef, FC, SyntheticEvent } from 'react';
import cs from 'classnames';
import { Typography } from '@mui/material';

import { Environment } from '@playq/octopus-common';

import { Label } from '/shared/Promotions/containers/Preview/styles';

import { IEnvironmentStatusProps } from '../types';

import { EnvLabel, StatusButton } from './styles';

export const EnvironmentStatus: FC<IEnvironmentStatusProps> = forwardRef((props: IEnvironmentStatusProps, ref) => {
  const {
    envs,
    id,
    onClick,
    classes = {},
    currentTheme: { palette: themePalette },
  } = props;

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  const inSandbox = envs.sandbox.includes(id);
  const inLive = envs.live.includes(id);

  return (
    <StatusButton
      onClick={handleClick}
      data-testid='button-status'
      className={classes.wrapper}
      ref={ref as any}
      type='button'
    >
      {inSandbox ? (
        <Label>
          <EnvLabel
            $themePalette={themePalette}
            env={Environment.Sandbox}
            className={cs(classes.label, classes.sandboxLabel)}
            clickable={true}
          >
            {Environment.Sandbox}
          </EnvLabel>
        </Label>
      ) : (
        <EnvLabel $themePalette={themePalette} className={cs(classes.label, classes.defaultLabel)} clickable={true}>
          <Typography variant='inherit' color='textSecondary'>
            <span>Sandbox</span>
          </Typography>
        </EnvLabel>
      )}
      {inLive ? (
        <EnvLabel
          $themePalette={themePalette}
          env={Environment.Live}
          className={cs(classes.label, classes.liveLabel)}
          clickable={true}
        >
          {Environment.Live}
        </EnvLabel>
      ) : (
        <EnvLabel $themePalette={themePalette} className={cs(classes.label, classes.defaultLabel)} clickable={true}>
          <Typography variant='inherit' color='textSecondary'>
            <span>Live</span>
          </Typography>
        </EnvLabel>
      )}
    </StatusButton>
  );
});
