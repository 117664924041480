import { useState } from 'react';
import { PlayCircleOutline } from '@mui/icons-material';

import { Mimes } from '/constants/file';

import { IPreviewComponentProps } from '../types';

import { useHtmlContainerStyle } from './styles';

export function HTMLPreview({
  url,
  autoPlay,
  onRebuildThumbnail: _,
  onClick,
  Fallback,
  ...rest
}: IPreviewComponentProps) {
  const classes = useHtmlContainerStyle();
  const [activeFrame, setActiveFrame] = useState(Boolean(autoPlay));

  const handleClick = () => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (onClick) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      onClick();
      return;
    }

    setActiveFrame(true);
  };

  return (
    <div className={classes.container} onClick={handleClick}>
      {!activeFrame ? (
        <PlayCircleOutline className={classes.playButton} />
      ) : (
        <iframe {...rest} src={url} frameBorder={0} title='url' sandbox='allow-scripts' />
      )}
    </div>
  );
}

HTMLPreview.mimes = Mimes.HTML;
