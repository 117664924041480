import { FC, useEffect, useState } from 'react';
import MarkdownIt from 'markdown-it';

interface IMarkdownProps {
  path: string;
}

export const Markdown: FC<IMarkdownProps> = ({ path }) => {
  const [markdown, setMarkdown] = useState<string>('');

  const md = new MarkdownIt();

  useEffect(() => {
    fetch(path)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setMarkdown(text);
      });
  }, [path]);

  const renderMarkdown = (source: string) => {
    return md.render(source);
  };

  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: renderMarkdown(markdown) }} />;
};
