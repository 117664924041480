import { IconButton, Palette, styled, Theme } from '@mui/material';
import { blueGrey, red } from '@mui/material/colors';
import { PhotoCamera } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { FiPackage } from 'react-icons/fi';

import { IThemeDependentStyledComponentProps } from '/common/models';

export const ImageStyled = styled('img')`
  display: flex;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  height: inherit;
  width: inherit;
  object-fit: contain;
`;

export const useVideoPreviewStyles = makeStyles((theme: Theme) => {
  const $themePalette: Palette = theme.palette;

  return {
    video: {
      display: 'block',
      objectFit: 'contain',
      overflow: 'auto',
      textDecoration: 'none',
      height: '100%',
      width: '100%',
      maxHeight: 'inherit',
      maxWidth: 'inherit',
    },
    videoContainer: {
      position: 'relative',
      maxHeight: 'inherit',
      maxWidth: 'inherit',
      height: '100%',
      width: '100%',
    },
    playButton: {
      position: 'absolute',
      cursor: 'pointer',
      height: '75%',
      width: '75%',
      color: $themePalette.action.selected,

      '&:hover': {
        color: $themePalette.action.active,
      },
    },
  };
});

export const RebuildThumbIconStyled = styled(IconButton)<IThemeDependentStyledComponentProps>`
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  background: ${blueGrey[500]};
  padding: 5px;
  z-index: ${({ $theme }: IThemeDependentStyledComponentProps) => ($theme as Theme).zIndex.tooltip};

  &:hover {
    background: ${red[500]};
  }
`;

const iconStyles = `
  width: calc(100% - 25%);
  height: calc(100% - 25%);
`;

export const PhotoCameraIcon = styled(PhotoCamera)`
  ${iconStyles}
` as typeof PhotoCamera;

export const PackageIcon = styled(FiPackage)`
  ${iconStyles}
`;

export const useHtmlContainerStyle = makeStyles((theme: Theme) => {
  const $themePalette: Palette = theme.palette;

  return {
    container: {
      display: 'flex',
      position: 'relative',
      width: 'inherit',
      height: 'inherit',
      justifyContent: 'center',
      alignItems: 'center',
    },
    playButton: {
      position: 'absolute',
      cursor: 'pointer',
      height: '75%',
      width: '75%',
      color: $themePalette.action.selected,

      '&:hover': {
        color: $themePalette.action.active,
      },
    },
  };
});
