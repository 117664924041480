import { FC, useEffect } from 'react';
import { Tooltip } from '@mui/material';

import { IFallbackProps } from '../types';

import { PhotoCameraIcon, PackageIcon } from './styles';

export const UnknownPreview: FC<IFallbackProps> = ({ onRenderFailure, fallbackTitle, isPackage, ...rest }) => {
  useEffect(() => {
    if (onRenderFailure) {
      onRenderFailure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Icon = isPackage ? <PackageIcon {...rest} /> : <PhotoCameraIcon {...rest} />;

  if (!fallbackTitle) {
    return Icon;
  }

  return <Tooltip title={fallbackTitle}>{Icon}</Tooltip>;
};
