import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';

import { Thumbnail, ThumbnailType } from '@playq/octopus2-files';

import { useSize } from '/hooks';
import { ImagePreview } from '/shared/FilePreview/previewComponents';
import { getThumbnail } from '/shared/FilePreview/helpers';

import { IThumbnailPreviewProps } from './types';
import { ThumbnailPreviewContainerStyled } from './styles';

export const ThumbnailPreview: FC<IThumbnailPreviewProps> = memo((props) => {
  const { thumbnails, Fallback, missed = false, ...rest } = props;

  const hasAnimated = useMemo(
    () => thumbnails.some((t: Thumbnail) => t.type_ === ThumbnailType.Animated),
    [thumbnails]
  );
  const firstRender = useRef(true);
  const ref = useRef(null);
  const sizes = useSize({ element: ref.current });
  const [thumbType, setThumbType] = useState<ThumbnailType>(() => ThumbnailType.Image);
  const [thumb, setThumb] = useState<Thumbnail | undefined>(() => getThumbnail(thumbnails, thumbType, sizes));

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setThumb(() => getThumbnail(thumbnails, thumbType, sizes));
  }, [thumbnails, sizes.height, sizes.width, thumbType, sizes]);

  const handleMouse = (type: ThumbnailType) => () => hasAnimated && setThumbType(type);

  return (
    <ThumbnailPreviewContainerStyled ref={ref}>
      {thumb && missed === false ? (
        <ImagePreview
          url={thumb.url}
          onMouseEnter={handleMouse(ThumbnailType.Animated)}
          onMouseLeave={handleMouse(ThumbnailType.Image)}
          Fallback={Fallback}
          {...rest}
        />
      ) : (
        <Fallback />
      )}
    </ThumbnailPreviewContainerStyled>
  );
});
