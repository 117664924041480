import { FC, useState } from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DateTime } from 'luxon';
import { isNil } from 'lodash';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { minDateDefault } from './constants';
import { IDateTimePickerProps } from './types';
import { useDatePickerStyles } from './styles';

export const DateTimePicker: FC<IDateTimePickerProps> = (props) => {
  const { date: original, time, onAccept, onChange, disabled, optional = false, className } = props;
  const now = new Date();

  const classes = useDatePickerStyles();
  const [date, setDate] = useState(() => {
    if (original) {
      return original > minDateDefault ? original : minDateDefault;
    }

    return optional ? undefined : minDateDefault;
  });

  const transformToDateTime = (jsDate: Date | undefined) => {
    if (jsDate === undefined) {
      return;
    }
    return DateTime.fromJSDate(jsDate);
  };

  function handleChangeDateTime(d: DateTime | null) {
    if (disabled || isNil(d)) {
      return;
    }

    setDate(d.toJSDate());
    onChange(d.toJSDate());
  }

  return (
    <>
      <StaticDatePicker
        className={className}
        value={transformToDateTime(date || now)}
        onChange={handleChangeDateTime}
        displayStaticWrapperAs='desktop'
        maxDate={transformToDateTime(props.maxDate)}
        minDate={transformToDateTime(props.minDate)}
        disableHighlightToday={!props.highlightCurrentDay}
        views={['year', 'month', 'day']}
        onAccept={onAccept}
        showDaysOutsideCurrentMonth={true}
      />
      {time && (
        <TimePicker
          value={transformToDateTime(date || now)}
          onChange={handleChangeDateTime}
          className={classes.timePicker}
          views={['hours', 'minutes']}
          ampm={false}
          timeSteps={{ minutes: 1 }}
        />
      )}
    </>
  );
};
