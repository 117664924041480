import { FC } from 'react';
import { styled } from '@mui/material';
import { Box, Typography, LinearProgressProps, LinearProgress } from '@mui/material';

export const Container = styled(Box)`
  width: 100%;
`;

export const LinearProgressWithLabel: FC<LinearProgressProps> = ({ value, className, ...rest }) => {
  return (
    <Container display='flex' alignItems='center' className={className}>
      <Box width='100%' mr={1}>
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        <LinearProgress variant={value ? 'determinate' : 'indeterminate'} value={value} {...rest} />
      </Box>
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {value && (
        <Box minWidth={35}>
          <Typography variant='body2' color='textSecondary'>
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      )}
    </Container>
  );
};
