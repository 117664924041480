import { TimeIterator } from '@playq/octopus-common';

import { makeTimeRange } from '/helpers/makeTimeRange';

import { initialRange } from './constants';

export const getInitialIterator = (): TimeIterator => {
  const timeRange = makeTimeRange(initialRange[0], initialRange[1]);
  const iterator = new TimeIterator();
  iterator.from_ = timeRange.from_;
  iterator.till = timeRange.till;
  return iterator;
};
