import { Formatter } from '@playq/irt';
import { Thumbnail, ThumbnailType } from '@playq/octopus2-files';

import { IBoundingRect } from '/hooks';

export enum Target {
  Type = 'response-content-type',
  Hash = 'Hash',
  Expires = 'Expires',
}

export const parsePath = (url: string, target: Target): string | undefined => {
  const urlSearchParams = new URL(url).searchParams;
  return urlSearchParams.get(target) || undefined;
};

export const getFileHash = (file: File): string => `${file.name}${file.lastModified}${file.size}`;

export const isUrlExpired = (url: string): boolean => {
  if (!url) {
    return true;
  }
  const expires = parsePath(url, Target.Expires);
  if (!expires) {
    return true;
  }

  const expiresTS = Formatter.readLocalDateTime(expires).getTime();
  return Date.now() > expiresTS;
};

export const findApproximateThumbnail = (thumbs: Thumbnail[], reference: IBoundingRect): Thumbnail => {
  const distance = (thumb: Thumbnail, ref: IBoundingRect) => {
    const refWithHeight: number = ref.height !== 0 ? (thumb.h - ref.height) ** 2 : 0;
    return Math.sqrt(refWithHeight + (thumb.w - ref.width) ** 2);
  };
  return thumbs.reduce((a, b) => (distance(a, reference) < distance(b, reference) ? a : b));
};

export const getThumbnail = (
  thumbs: Thumbnail[],
  type: ThumbnailType,
  reference: IBoundingRect
): Thumbnail | undefined => {
  const filtered: Thumbnail[] = thumbs.filter((thumb: Thumbnail) => thumb.type_ === type);
  if (filtered.length) {
    return findApproximateThumbnail(filtered, reference);
  }
  return undefined;
};
