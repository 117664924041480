import { useMemo } from 'react';
import * as _ from 'lodash';

import { isUrlExpired, parsePath, Target } from '/shared/FilePreview/helpers';
import { SourceType } from '/shared/FilePreview/types';
import { ProcessPreview } from '/shared/FilePreview/ProcessPreview';

import { urlStorage } from '../urlStorage';
import { IHandlerProps } from '../types';

const processURL = (source: string, id?: string): string | undefined => {
  if (!id) {
    return;
  }
  const cached = urlStorage.get(id);
  if (cached && !isUrlExpired(cached)) {
    return cached;
  }
  urlStorage.set(id, source);
  return source;
};

export function UrlHandler({ source, id, ...rest }: IHandlerProps<string>) {
  const urlID = useMemo<string | undefined>(() => id || parsePath(source, Target.Hash), [source, id]);
  const url = useMemo<string | undefined>(() => processURL(source, urlID), [urlID, source]);
  return <ProcessPreview url={url} {...rest} />;
}

UrlHandler.canProcess = (source: SourceType): boolean => _.isString(source);
