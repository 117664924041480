import { createTheme, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getOppositeColor } from '/helpers/color';

const versionPickerColor = '#ff9800';

export const useControlBarStyles = makeStyles((theme: Theme) => ({
  stickyContainer: {
    position: 'sticky',
    zIndex: theme.zIndex.appBar,
    margin: '0 -15px 0 -15px',
    padding: '0 15px 10px 15px',
  },
  root: {
    width: '100%',
    overflow: 'hidden',
    padding: '2px 4px 2px 15px',
    backgroundColor: theme.palette.background.paper,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 64,
    height: 'auto',
    width: '100%',
  },
  input: {
    textAlignment: 'right' /* stylelint-disable-line property-no-unknown */,
    marginLeft: 8,
    minWidth: 200,
  },
  logData: {
    flex: 1,
    minWidth: 0,
    marginRight: 10,
  },
  logDataInfo: {
    whiteSpace: 'nowrap',
    '& span:first-of-type': {
      lineHeight: 1.6,
    },
    lineHeight: 0,
  },
  logDataInfoWrapper: {
    lineHeight: 1.4,
  },
  logDataInfoColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& span:first-of-type': {
      marginBottom: '5px',
    },
  },
  logDataInfoRow: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    fontSize: 12,
    width: '100%',
    '& svg': {
      marginRight: 5,
    },
  },
  logDataInfoText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  content: {
    margin: '0 5px',
  },
  iconButton: {
    marginRight: 10,
    padding: 10,
  },
  iconInline: {
    fontSize: 20,
  },
  button: {
    marginRight: 5,
    minWidth: 150,
  },
  pickerButton: {
    marginRight: theme.spacing(1),
    paddingRight: 0,
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  pickerButtonHighlighted: {
    backgroundColor: `${theme.palette.warning.dark} !important`,
  },
  pickerButtonIcon: {
    fontSize: 14,
    verticalAlign: 'bottom',
  },
  expandButton: {
    marginRight: 5,
    minWidth: 70,
  },
  divider: {
    width: 1,
    height: 28,
    margin: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  saveButtonHiddenLabel: {
    opacity: 0,
  },
  saving: {
    position: 'absolute',
  },
  messagesCard: {
    width: 1000,
    height: 600,
  },
  hidden: { visibility: 'hidden', position: 'absolute' },
  error: {
    position: 'absolute',
    bottom: 10,
    fontSize: 12,
  },
}));

export const useRefreshControlBarButtonStyles = makeStyles({
  tooltip: {
    marginTop: '4px !important',
  },
});

export const versionPickerTheme = createTheme({
  palette: {
    primary: {
      main: versionPickerColor,
      contrastText: getOppositeColor(versionPickerColor),
    },
  },
});
