/* eslint-disable react/no-array-index-key */
import { ComponentType, FC, ReactNode } from 'react';
import { ExtendButtonBase, Fab, FabTypeMap, LinearProgress, SvgIconProps, Tooltip } from '@mui/material';
import { InfoRounded as InfoRoundedIcon } from '@mui/icons-material';
import cs from 'classnames';

import { mergeClasses } from '/helpers';

import { useStyles } from './styles';

interface IBlockThemedClasses extends Record<string, string | undefined> {
  error?: string;
  blockContent?: string;
  block?: string;
  label?: string;
  blockActions?: string;
  spinner?: string;
}

interface IBlockThemedProps {
  label: string | ReactNode;
  children: ReactNode;
  className?: string;
  classes?: IBlockThemedClasses;
  pending?: boolean;
  actions?: IBlockThemedAction[];
  error?: string | boolean;
}

export interface IBlockThemedAction {
  icon: ComponentType<SvgIconProps>;
  disabled?: boolean;
  tooltip?: string | ReactNode;
  onClick: () => void;
  fabProps?: Omit<ExtendButtonBase<FabTypeMap>, 'disabled' | 'onClick' | 'size'>;
  dataCy?: string;
}

export const BlockThemed: FC<IBlockThemedProps> = (props) => {
  const { label, error, classes: propsClasses = {}, children, className, pending, actions } = props;

  const internalClasses = useStyles();
  const classes = mergeClasses<IBlockThemedClasses>(internalClasses, propsClasses);

  const blockLabel: ReactNode = typeof label === 'string' ? <b>{label}</b> : label;

  const actionsButtons = actions
    ? actions.map((action: IBlockThemedAction, idx: number) => {
        const Icon = action.icon;
        const actionButton = (
          <Fab
            key={idx}
            size='small'
            color='primary'
            disabled={action.disabled}
            onClick={action.onClick}
            className={classes.button && classes.buttonActions}
            {...action.fabProps}
          >
            <Icon data-testid={action.dataCy ? action.dataCy : 'icon-add'} />
          </Fab>
        );

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (action.tooltip) {
          return (
            <Tooltip key={idx} disableFocusListener={true} title={action.tooltip} placement='top'>
              {actionButton}
            </Tooltip>
          );
        }

        return actionButton;
      })
    : null;

  const labelString = typeof label === 'string' ? label : 'label';
  return (
    <div
      data-testid={`${labelString.replace('*', '').trim()}-block`}
      className={cs(classes.block, error != null && classes.error, className)}
    >
      {pending && <LinearProgress classes={{ root: classes.spinner }} color='secondary' />}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      <label className={cs(classes.label, error && classes.errorLabel)}>
        {blockLabel}
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {error && typeof error === 'string' && (
          <Tooltip title={error} placement='top'>
            <InfoRoundedIcon fontSize='small' />
          </Tooltip>
        )}
      </label>
      <span className={classes.blockActions}>{actionsButtons}</span>
      <div className={classes.blockContent}>{children}</div>
    </div>
  );
};
